<template>
  <SupportInfo
    class="cart-supportInfoModule"
    scene="Cart"
    :assistPolicy="assistPolicy"
    :language="language"
    :paymentSafetyExperimentAbt="paymentSafetyExperimentAbt"
    :GB_cssRight="locals.GB_cssRight"
  />
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartSupportInfoContainer'
})
</script>
<script setup>
import { computed } from 'vue'
import SupportInfo from 'public/src/pages/checkout_new/pages/support_info/index.vue'
import { useStore } from '../../hooks/useStore.js'

const { state } = useStore()

const language = computed(() => {
  return state.language || {}
})

const cartAbtInfo = computed(() => {
  return state.cartAbtInfo || {}
})

const locals = computed(() => {
  return state.locals
})

const assistPolicy = computed(() => {
  return state.assistPolicyData || null
})

const paymentSafetyExperimentAbt = computed(() => cartAbtInfo.value?.PaymentSafetyExperiment?.param?.PaymentSafetyCheckout || '')

</script>

<style lang="less" scoped>
.cart-supportInfoModule {
  padding-left: 12/75rem;
  padding-right: 12/75rem;
  overflow: hidden;
  padding-top: 20/75rem;
  padding-bottom: 20/75rem;
}
</style>
