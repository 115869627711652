<template>
  <div class="cart-batch__footer-yard">
    <div
      v-show="batchEditItemIds.length > 0 && batchActive"
      class="cart-batch__num"
    >
      <span>{{ `${language.SHEIN_KEY_PWA_15742} ${batchEditItemIds.length}` }}</span>
    </div>
    <div class="cart-batch__footer">
      <CartBatchAll v-if="!cartBottomStyle" />
      <span class="cart-batch__operation">
        <!-- Move to wishlist -->
        <a
          
          class="cart-batch__operation-item cart-batch__operation-M2W"
          :class="{ disabled: batchEditItemIds.length == 0 }"
          href="javascript:;"
          tabindex="0"
          role="button"
          da-event-click="1-8-7-18"
          @click="preHandleBatchM2W"
        >{{ language.SHEIN_KEY_PWA_17044 }}</a>
        <!-- Delete -->
        <a
          
          class="cart-batch__operation-item cart-batch__operation-detete"
          :class="{ disabled: batchEditItemIds.length == 0 }"
          href="javascript:;"
          tabindex="0"
          role="button"
          da-event-click="1-8-7-23"
          @click="preHandleBatchDelete"
        >{{ language.SHEIN_KEY_PWA_15198 }}</a>
      </span>
    </div>
    <!-- ipx 适配 -->
    <div class="iphoneX-block"></div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CartBatchFooter',
})
</script>
<script setup>
import { computed, watch } from 'vue'
import { useStore } from '../../hooks/useStore'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { daEventCenter } from 'public/src/services/eventCenter'
import { template } from '@shein/common-function'
import { useCartDeleteDialog } from 'public/src/pages/cart_v2/components/cartDeleteDialog/useCartDeleteDialog'
import CartBatchAll from 'public/src/pages/cart_v2/components/cartBatchFooter/CartBatchAll.vue'

const { state, commit, dispatch, getters } = useStore()
const { showCartDeleteDailog } = useCartDeleteDialog()
const batchEditItemIds = computed(() => state.batchEditItemIds)
const batchActive = computed(() => state.batchActive)
const language = computed(() => state.language)
const loginStatus = computed(() => state.loginStatus)
const batchDeleteStayPopUp = computed(() => state.batchDeleteStayPopUp)
const carts = computed(() => state.carts)
const cartBottomStyle = computed(() => getters.cartBottomStyle)

const handleBatchM2W = () => {
  commit('updateState', {
    key: 'loadingShow',
    value: true
  })

  const reqBatchIds = batchEditItemIds.value
  const reqCarts = [].concat(carts.value)

  dispatch('fetchCartBatchWish', { params: { id: reqBatchIds } })
    .then(async res => {
      commit('updateState', {
        key: 'loadingShow',
        value: false
      })
      if (res.code == '0') {
        resetBatchEditItemIds()
        commit('handleBatchActive', { value: false })
      } else {
        if (res.code == -4 || res.code == -2) {
          SToast(language.value.SHEIN_KEY_PWA_17639)
        } else if (Number(res.code) === 400427 && res.info) {
          SToast(template(res.info.limit, language.value.SHEIN_KEY_PWA_18556))
        } else {
          SToast(res.msg)
        }
      }

      daEventCenter.triggerNotice({
        daId: '1-8-7-20',
        extraData: {
          carts: reqCarts,
          batchIds: reqBatchIds,
          result: res.code == '0' ? 1 : 0
        }
      })
      daEventCenter.triggerNotice({
        daId: res.code == '0' ? '1-8-7-22' : '1-8-7-21',
        extraData: {
          carts: reqCarts,
          batchIds: reqBatchIds,
        }
      })
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log(err)
      commit('updateState', {
        key: 'loadingShow',
        value: false
      })
    })
}

const preHandleBatchM2W = () => {
  if (batchEditItemIds.value.length == 0) return
  commit('handleModal', {
    text: language.value.SHEIN_KEY_PWA_17954,
    okCallback: async () => {
      if (loginStatus.value) {
        handleBatchM2W()
      } else {
        let loginBeforeCheckedGoodsid = carts.value.filter(item => batchEditItemIds.value.includes(item.id)).map(item => (item.mall_code + '-' + item.store_code + '-' + item.skuCode))
        const { result, res } = await dispatch('handleCartLogin', { type: 'wishlist' })
        if (res.code == 0) {
          if (carts.value.length) {
            let itemIds = carts.value.filter(item => loginBeforeCheckedGoodsid.includes(item.mall_code + '-' + item.store_code + '-' + item.skuCode)).map(item => item.id)
            commit('updateState', {
              key: 'batchEditItemIds',
              value: itemIds || []
            })
          }
          if (result.equalCart) {
            handleBatchM2W()
          } else {
            SToast(language.value.SHEIN_KEY_PWA_17961, 5000)
          }
        }

      }
    },
    cancelCallback: () => {
    },
    cancelDaId: '1-8-7-19'
  })
}

const preHandleBatchDelete = () => {
  if (batchEditItemIds.value.length == 0) return
  showCartDeleteDailog({
    type: 'batch',
    data: batchDeleteStayPopUp.value,
    ids: batchEditItemIds.value,
    language: language.value,
  })

}

const resetBatchEditItemIds = () => {
  commit('updateState', {
    key: 'batchEditItemIds',
    value: []
  })
}

</script>
<style lang="less" scoped>
.cart-batch__footer-yard {
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 19;
  /* stylelint-disable-line declaration-property-value-blacklist */
  -webkit-transform: translate3d(0, 0, 99px);
  transform: translate3d(0, 0, 99px);
}

.cart-batch__footer {
  display: flex;
  justify-content: space-between;
  align-self: center;
  bottom: 0;
  width: 100%;
  .border-dpr(border-top, 2px, #e5e5e5);
  background: rgba(255, 255, 255, .95);
  padding: .32rem;
}

.cart-batch__operation {
  display: flex;
  min-width: 0;
  margin-left: auto;
}

.cart-batch__operation-item {
  font-size: 14px;
  margin-left: 0.4267rem;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;

  &.disabled {
    opacity: .3;
  }
}

.cart-batch__operation-M2W {
  flex: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}

.cart-batch__num {
  display: inline-block;
  margin-left: .32rem;
  margin-bottom: 0.32rem;
  z-index: 1;
  /* stylelint-disable-line declaration-property-value-blacklist */
  border-radius: 9999px;
  color: #fff;
  overflow: hidden;
  white-space: nowrap;
  max-width: 5rem;
  background-clip: padding-box;
  vertical-align: middle;
  font-size: 10px;
  background: hsla(0, 0%, 0%, 0.6);
  border: 1px solid rgba(255, 255, 255, .6);
  /* rw:begin */
  background: rgba(51, 51, 51, 0.6);
  border: none;

  /* rw:end */
  span {
    display: inline-block;
    margin: 0 .24rem;
    line-height: 0.48rem;
  }
}


.cart-batch__operation-detete {
  color: @sui_color_unusual;
}
</style>
